export default {
  axios: {
    url: 'https://booksprout.co/api/'
  },
  apolloClient: {
    url: 'https://booksprout.co/api/graphql'
  },
  quadernoApiKey: 'pk_live_UMHswonDASRsoxBWxNgq',
  stripeApiKey: 'pk_live_vfxh23gdLToJz5E3cRUsXlHD'
}
