import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'
import {
  SET_READY_TO_RENDER,
  SET_UNREAD_MESSAGE_COUNT,
  SET_UNREAD_NOTIFICATION_COUNT,
  SET_USER_STATS
} from '../store/actions/ui'
import {
  AuthServiceInterface,
  AxiosServiceInterface,
  BsSpinner,
  inject,
  MessageThreadServiceInterface,
  NotificationServiceInterface,
  SET_USER,
  UserServiceInterface
} from '../../index'
import { MessageThreadDto, newDate, NotificationDto, UserDto, UserStatsDto, validStr } from 'booksprout'
import { Loading, LocalStorage } from 'quasar'
import initHelpHero, { ChecklistEvent, ChecklistEventInfo } from 'helphero'
import { Getter } from 'vuex-class'
let HelpHero: any = void 0

const myIcons: { [key: string]: string } = {
  'app:active': '#bs-active',
  'app:active-arcs': '#bs-active-arcs',
  'app:all-authors': '#bs-all-authors',
  'app:amazon': '#bs-amazon',
  'app:amazon-original': '#bs-amazon-original',
  'app:american-express': '#bs-american-express',
  'app:apple': '#bs-apple',
  'app:arcs': '#bs-arcs',
  'app:arrow-down': '#bs-arrow-down',
  'app:arrow-left': '#bs-arrow-left',
  'app:arrow-left-slim': '#bs-arrow-left-slim',
  'app:arrow-right': '#bs-arrow-right',
  'app:arrow-right-slim': '#bs-arrow-right-slim',
  'app:arrow-right-alt': '#bs-arrow-right-alt',
  'app:arrow-up': '#bs-arrow-up',
  'app:audible': '#bs-audible',
  'app:audiobook': '#bs-audiobook',
  'app:audiobook-alt': '#bs-audiobook-alt',
  'app:author-role': '#bs-author-role',
  'app:authors-direct': '#bs-authors-direct',
  'app:award': '#bs-award',
  'app:back': '#bs-back',
  'app:badge-filled': '#bs-badge-filled',
  'app:badge-new-reviewer': '#bs-badge-new-reviewer',
  'app:badge-outline': '#bs-badge-outline',
  'app:badge-valued-reviewer': '#bs-badge-valued-reviewer',
  'app:badge-top-reviewer': '#bs-badge-top-reviewer',
  'app:ban': '#bs-ban',
  'app:barnes': '#bs-barnes',
  'app:billing': '#bs-billing',
  'app:bin': '#bs-bin',
  'app:block': '#bs-block',
  'app:book': '#bs-book',
  'app:bookbub': '#bs-bookbub',
  'app:bookmark': '#bs-bookmark',
  'app:books': '#bs-books',
  'app:booksprout': '#bs-booksprout',
  'app:booksprout-dense': '#bs-booksprout-dense',
  'app:booksprout-leaf': '#bs-booksprout-leaf',
  'app:browse': '#bs-browse',
  'app:calendar': '#bs-calendar',
  'app:calendar-next': '#bs-calendar-next',
  'app:calendar-prev': '#bs-calendar-prev',
  'app:cancel-filled': '#bs-cancel-filled',
  'app:cancelled-arcs': '#bs-cancelled-arcs',
  'app:cancelled-review': '#bs-cancelled-review',
  'app:check': '#bs-check',
  'app:check-dense': '#bs-check-dense',
  'app:check-filled': '#bs-check-filled',
  'app:check-round': '#bs-check-round',
  'app:check-round-thin': '#bs-check-round-thin',
  'app:check-thin': '#bs-check-thin',
  'app:close': '#bs-close',
  'app:close-circle': '#bs-close-circle',
  'app:close-fat': '#bs-close-fat',
  'app:complete-arcs': '#bs-complete-arcs',
  'app:copy': '#bs-copy',
  'app:dashboard': '#bs-dashboard',
  'app:date': '#bs-date',
  'app:date-picker': '#bs-date-picker',
  'app:delayed': '#bs-delayed',
  'app:delayed-arcs': '#bs-delayed-arcs',
  'app:delete': '#bs-delete',
  'app:discount': '#bs-discount',
  'app:discover': '#bs-discover',
  'app:done': '#bs-done',
  'app:dots-filled': '#bs-dots-filled',
  'app:double-arrow': '#bs-double-arrow',
  'app:download': '#bs-download',
  'app:download-solid': '#bs-download-solid',
  'app:due-arcs': '#bs-due-arcs',
  'app:ebook': '#bs-ebook',
  'app:ebook-alt': '#bs-ebook-alt',
  'app:ellipse': '#bs-ellipse',
  'app:ellipse-horizontal': '#bs-ellipse-horizontal',
  'app:email': '#bs-email',
  'app:email-alt': '#bs-email-alt',
  'app:epub-preview': '#bs-epub-preview',
  'app:equalizer': '#bs-equalizer',
  'app:error': '#bs-error',
  'app:exclamation': '#bs-exclamation',
  'app:excluded': '#bs-excluded',
  'app:exclusive': '#bs-exclusive',
  'app:external-review': '#bs-external-review',
  'app:facebook': '#bs-facebook',
  'app:facebook-small': '#bs-facebook-small',
  'app:filter': '#bs-filter',
  'app:flag': '#bs-flag',
  'app:flag-filled': '#bs-flag-filled',
  'app:flag-point': '#bs-flag-point',
  'app:following': '#bs-following|0 0 30 16', // use pipe separator to provide viewBox, see https://v1.quasar.dev/vue-components/icon#svg-use-way
  'app:following-authors': '#bs-following-authors',
  'app:forward': '#bs-forward',
  'app:glasses': '#bs-glasses',
  'app:goodreads': '#bs-goodreads',
  'app:google-play': '#bs-google-play',
  'app:group-all': '#bs-group-all',
  'app:head-phones': '#bs-head-phones',
  'app:inactive': '#bs-inactive',
  'app:inbox': '#bs-inbox',
  'app:info': '#bs-info',
  'app:instagram': '#bs-instagram',
  'app:itunes': '#bs-itunes',
  'app:jcb': '#bs-jcb',
  'app:kindle': '#bs-kindle',
  'app:kobo': '#bs-kobo',
  'app:lightning': '#bs-lightning',
  'app:lightning-solid': '#bs-lightning-solid',
  'app:link': '#bs-link',
  'app:lock': '#bs-lock',
  'app:logout': '#bs-logout',
  'app:logout-alt': '#bs-logout-alt',
  'app:magnifier': '#bs-magnifier',
  'app:magnifier-dense': '#bs-magnifier-dense',
  'app:maintenance': '#bs-maintenance',
  'app:mastercard': '#bs-mastercard',
  'app:message-filled': '#bs-message-filled',
  'app:messages': '#bs-messages',
  'app:messages-no-unread': '#bs-messages-no-unread',
  'app:minus': '#bs-minus',
  'app:mobile-menu': '#bs-mobile-menu',
  'app:mobile-menu-close': '#bs-mobile-menu-close',
  'app:mobile-next': '#bs-mobile-next',
  'app:new-and-unreleased': '#bs-new-and-unreleased',
  'app:new-arc-participant': '#bs-new-arc-participant',
  'app:new-review': '#bs-new-review',
  'app:notifications': '#bs-notifications',
  'app:notifications-no-unread': '#bs-notifications-no-unread',
  'app:offline': '#bs-offline',
  'app:open-mail': '#bs-open-mail',
  'app:open-mail-alt': '#bs-open-mail-alt',
  'app:padlock': '#bs-padlock',
  'app:padlock-circle': '#bs-padlock-circle',
  'app:password-hidden': '#bs-password-hidden',
  'app:password-visible': '#bs-password-visible',
  'app:past-releases': '#bs-past-releases',
  'app:pen-names': '#bs-pen-names',
  'app:pen-names-alt': '#bs-pen-names-alt',
  'app:pen-names-alt-no-unread': '#bs-pen-names-alt-no-unread',
  'app:pencil': '#bs-pencil',
  'app:pending': '#bs-pending',
  'app:pending-arcs': '#bs-pending-arcs',
  'app:pending-dots': '#bs-pending-dots',
  'app:people': '#bs-people',
  'app:plug-female': '#bs-plug-female',
  'app:plug-male': '#bs-plug-male',
  'app:plus': '#bs-plus',
  'app:plus-rounded': '#bs-plus-rounded',
  'app:publisher-role': '#bs-publisher-role',
  'app:question-circle': '#bs-question-circle',
  'app:reader': '#bs-reader',
  'app:reader-no-unread': '#bs-reader-no-unread',
  'app:reader-role': '#bs-reader-role',
  'app:recycle': '#bs-recycle',
  'app:recycle-vertical': '#bs-recycle-vertical',
  'app:rejected-review': '#bs-rejected-review',
  'app:report-pirate': '#bs-report-pirate',
  'app:reviewer': '#bs-reviewer',
  'app:reviews': '#bs-reviews',
  'app:ribbon': '#bs-ribbon',
  'app:sent': '#bs-sent',
  'app:settings': '#bs-settings',
  'app:share': '#bs-share',
  'app:sheet': '#bs-sheet',
  'app:smashwords': '#bs-smashwords',
  'app:social-share': '#bs-social-share',
  'app:sort': '#bs-sort',
  'app:star': '#bs-star',
  'app:star-empty': '#bs-star-empty',
  'app:star-full': '#bs-star-full',
  'app:star-half': '#bs-star-half',
  'app:star-outline': '#bs-star-outline',
  'app:star-tick': '#bs-star-tick',
  'app:stop': '#bs-stop',
  'app:stop-alt': '#bs-stop-alt',
  'app:stop-thin': '#bs-stop-thin',
  'app:support': '#bs-support',
  'app:tada-l': '#bs-tada-l',
  'app:tada-r': '#bs-tada-r',
  'app:tag-valued-reviewer': '#bs-tag-valued-reviewer',
  'app:tag-top-reviewer': '#bs-tag-top-reviewer',
  'app:team-members': '#bs-team-members',
  'app:teams': '#bs-teams',
  'app:teams-person': '#bs-teams-person',
  'app:tiktok': '#bs-tiktok',
  'app:time-to-publish': '#bs-time-to-publish',
  'app:times-circle': '#bs-times-circle',
  'app:trash': '#bs-trash',
  'app:twitter': '#bs-twitter',
  'app:unread': '#bs-unread',
  'app:upgrade': '#bs-upgrade',
  'app:va-role': '#bs-va-role',
  'app:vertical-dots': '#bs-vertical-dots',
  'app:visa': '#bs-visa',
  'app:visit-link': '#bs-visit-link',
  'app:website': '#bs-website',
  'app:your-teams': '#bs-your-teams',
  'app:x': '#bs-x'
}

// @ts-ignore
@Component
export abstract class BaseApp extends CommonBaseMixin {
  @inject('MessageThreadService')
  readonly messageThreadService!: MessageThreadServiceInterface<MessageThreadDto>

  @inject('AuthService')
  authService!: AuthServiceInterface

  @inject('UserService')
  userService!: UserServiceInterface<UserDto>

  @inject('NotificationService')
  notificationService!: NotificationServiceInterface<NotificationDto>

  @inject('AxiosService')
  axiosService!: AxiosServiceInterface

  @Getter('getIsReadyToRender') isReadyToRender!: boolean

  loggedInUserActionsInterval: any = void 0
  runIntervalActions = true

  shouldSendHelpHeroData (stats: UserStatsDto) {
    let shouldSend = false

    shouldSend = shouldSend ||
      // We're forcing them to show until this date
      this.MODULE_CONSTANTS.APP.HELP_HERO_STOP_HELP >= newDate() ||
      // or they have some data which indicates they've not yet completed a certain step
      stats.arcCount === 0 ||
      stats.penNameCount === 0 ||
      stats.bookCount === 0 ||
      stats.arcTeamCount === 0 ||
      stats.reviewsCount === 0 ||
      stats.completeArcCount === 0 ||
      stats.newReviewCount === 0 ||
      stats.pendingTeamMembers === 0 ||
      stats.anyArcHasParticipant === false ||
      stats.anyPenNameHasFollower === false ||
      stats.anyTeamHasMember === false ||
      stats.hasHadTeamMemberForOneMonth === false ||
      stats.hasReviewOlderThanOneMonth === false

    const completedAllTours = this.MODULE_CONSTANTS.APP.HELP_HERE_COMPLETE_ON_TOURS.sort().join(',') ===
      this.mimicOrAuthenticatedUser.completedTours?.split(',').sort().join(',')

    /*console.log(
      'Should Send Help Hero Log: ',
      shouldSend,
      validStr(this.mimicOrAuthenticatedUser.authorRole),
      this.isReviewerApp === false,
      completedAllTours,
      this.MODULE_CONSTANTS.APP.HELP_HERE_COMPLETE_ON_TOURS,
      this.mimicOrAuthenticatedUser.completedTours
    )*/

    // They have stats which mean we should send the data
    return shouldSend &&
      // AND They haven't completed all the tours (manually or otherwise)
      !completedAllTours &&
      // AND they are an Author
      validStr(this.mimicOrAuthenticatedUser.authorRole) &&
      // AND we're on the authorApp
      this.isReviewerApp === false
  }

  intervalRoutines () {
    if (this.mimicOrAuthenticatedUser.id !== void 0 && this.runIntervalActions) {
      this.notificationService.globalLoader(false)
      this.userService.globalLoader(false)
      this.axiosService.globalLoader(false)
      this.messageThreadService.globalLoader(false)

      this.userService.getById(this.mimicOrAuthenticatedUser.id).then(async user => {
        if (user?.isLocked) {
          return this.$router.push('/logout')
        }

        if (user) {
          void this.$store.dispatch(SET_USER, user)
          const messageCount = await this.messageThreadService.getUnreadThreadCount()
          void this.$store.dispatch(SET_UNREAD_MESSAGE_COUNT, messageCount)

          const stats =  await this.userService.getUserStats()
          await this.$store.dispatch(SET_USER_STATS, stats)
          if (this.shouldSendHelpHeroData(stats) && process.env.BUILD_MODE === 'master' && this.authenticatedUser && !this.authenticatedUser.isAdmin) {
            // remove the typename - we don't need to send that.
            const {
              // @ts-ignore
              __typename,
              ...statsToSend
            } = stats

            HelpHero.identify(this.mimicOrAuthenticatedUser.id, {
              name: this.mimicOrAuthenticatedUser.name,
              email: this.mimicOrAuthenticatedUser.emailAddress,
              ...statsToSend
            })
          }

          const unreadNotifications = await this.notificationService.getUnreadNotificationCount()
          void this.$store.dispatch(SET_UNREAD_NOTIFICATION_COUNT, unreadNotifications)
        }
      }).catch(e => {
        if (e?.message === 'Unauthorized') {
          // do nothing
        } else {
          throw e
        }
      }).finally(() => {
        this.notificationService.globalLoader(true)
        this.userService.globalLoader(true)
        this.messageThreadService.globalLoader(true)
      })

      if (!this.isMobileApp) {
        this.axiosService.axios.post('auth/live', {}, {
          timeout: 5000
        }).then((res: { data: { version: string } }) => {
          if (res.data.version !== this.MODULE_CONSTANTS.APP.VERSION) {
            this.$q.dialog({
              component: this.$bs.confirmDialog,
              parent: this,
              heading: 'Application Updated',
              bodyHtml: 'This application has been updated, please confirm to reload the page. If you have any unsaved changes, please select "No", save your changes then reload manually.'
            }).onOk(() => {
              document.location.reload()
            })
          }
        }).catch(e => {
          // if it is timeout error
          if (e?.code === 'ECONNABORTED') {
            // this prevents the error from being sent to Sentry
            // and there is not much we can do about it anyway
            console.log(e)
          } else {
            throw e
          }
        }).finally(() => {
          this.axiosService.globalLoader(true)
        })
      }
    }
  }

  private configureLoading () {
    Loading.setDefaults({
      // @ts-ignore
      spinner: BsSpinner
    })
  }

  private configureCalendar () {
    this.$q.iconSet.datetime.arrowLeft = 'app:calendar-prev'
    this.$q.iconSet.datetime.arrowRight = 'app:calendar-next'
  }

  /**
   * When a user has completed a tour, update our user data to show it so we don't keep sending them for a tour
   * @param event
   * @param info
   */
  onChecklistCompleted (event: ChecklistEvent, info: ChecklistEventInfo) {
    const completedTours = this.mimicOrAuthenticatedUser.completedTours
      ? this.mimicOrAuthenticatedUser.completedTours.split(',')
      : []

    if (completedTours.indexOf(info.checklist.name) === -1) {
      completedTours.push(info.checklist.name)
    }

    this.userService.update({
      id: this.mimicOrAuthenticatedUser.id,
      completedTours: completedTours.join(',')
    }).then(user => {
      return this.$store.dispatch(SET_USER, user)
    })
  }

  created () {
    const iconPath = 'svguse:/cdn/app-icon-sprite.svg'

    this.$q.iconMapFn = (iconName) => {
      const icon = myIcons[iconName]
      if (icon !== void 0) {
        return { icon: iconPath + icon }
      }
      return void 0
    }
  }

  mounted () {
    this.intervalRoutines()

    // disable for testing env, it is enough to call once (above)
    // sometimes tests fail waiting for api and api can hang up at any test
    // note: there is a notification icon test in reviewer app which is disabled and depends on these routines
    if (![process.env.ENVIRONMENT, process.env.BUILD_MODE].includes('testing')) {
      this.loggedInUserActionsInterval = setInterval(() => {
        this.intervalRoutines()
      }, 60000)
    }

    this.configureLoading()
    this.configureCalendar()

    if (process.env.BUILD_MODE === 'master' && this.authenticatedUser && !this.authenticatedUser.isAdmin) {
      HelpHero = initHelpHero('rJcQnSjtzVY')
      HelpHero.on('checklist_completed', this.onChecklistCompleted)
    }

    void this.$store.dispatch(SET_READY_TO_RENDER, true)
  }

  updated () {
    // router won't be ready immediately, so we need to wait for it
    if (this.$route.query?.ref) {
      LocalStorage.set('fp_ref', this.$route.query.ref)
    }
  }

  beforeDestroy () {
    clearInterval(this.loggedInUserActionsInterval)

    if (process.env.BUILD_MODE === 'master' && this.authenticatedUser && !this.authenticatedUser.isAdmin) {
      HelpHero.off('checklist_completed', this.onChecklistCompleted)
    }
  }
}
