// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'
import { QBtn, QIcon } from 'quasar'

@Component
export default class BsShareBtn extends CommonMixin {
  @Prop({ type: String }) public readonly url!: string
  @Prop({ type: String, default: '' }) readonly pinterestQuery!: string
  @Prop({ type: Boolean }) public readonly facebook!: boolean
  @Prop({ type: Boolean }) public readonly twitter!: boolean
  @Prop({ type: Boolean }) public readonly pinterest!: boolean

  public get btnData (): { icon: string, shareLink: string, color: string } {
    if (this.pinterest) {
      return {
        icon: 'fab fa-pinterest',
        shareLink: `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(this.url)}${this.pinterestQuery}`,
        color: 'bs-pinterest-r'
      }
    } else if (this.twitter) {
      return {
        icon: 'app:x',
        shareLink: `https://x.com/intent/post?url=${encodeURIComponent(this.url)}`,
        color: 'bs-x-b'
      }
    } else {
      return {
        icon: 'fab fa-facebook',
        shareLink: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}&amp;src=sdkpreparse`,
        color: 'bs-fb-b'
      }
    }
  }

  public __renderIcon (h: CreateElement) {
    return h(QIcon, {
      props: {
        name: this.btnData.icon,
        size: '1.375rem'
      }
    })
  }

  public render (h: CreateElement) {
    return h(QBtn, {
      staticClass: 'bs-share-btn',
      props: this.getRenderProps({
        type: 'a',
        color: this.btnData.color,
        unelevated: true
      }),
      attrs: this.getAttributes({
        href: this.btnData.shareLink,
        target:'_blank'
      }),
      on: this.getListeners()
    }, [
      this.__renderIcon(h),
      this.slot(this, 'default')
    ])
  }
}
