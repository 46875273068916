export default {
  axios: {
    url: 'https://sandbox.booksprout.co/api/'
  },
  apolloClient: {
    url: 'https://sandbox.booksprout.co/api/graphql'
  },
  quadernoApiKey: 'pk_test_zVtjVCesmXZo--pT31C-',
  stripeApiKey: 'pk_test_em1d5DZ5uSDda8RnUhRWjcAg'
}
