import { copyToClipboard, date, LocalStorage } from 'quasar'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import VueI18n, { TranslateResult } from 'vue-i18n'
import { inject } from 'inversify-props'
import {
  bsConstants,
  UserDto,
  validInt,
  validStr,
  PermissionService,
  BookDto,
  HasIndex,
  updateUrlHash, newDate, roundWithPrecision, toCamelCase, locationOrigin
} from 'booksprout'
import { LinkService } from '../../modules/_base/link.service'
import { AuthenticatedUser } from '../../modules/_base/auth/auth.service.interface'
import { DATE_FORMAT } from '../../constants'
import { ellipsis, IsReviewerApp } from '../../utils'
import * as emailValidator from 'email-validator'
import { StoreService } from '../../modules/_base/store.service'
import { ConfigService } from '../../modules/_base/config.service'
import { requireConfigFile } from '../../../../../../apps/app-reviewer/src/constants'
import { AxiosService } from '../../modules/_base/axios/axios.service'
import { MetaState, SET_LAST_ERROR, SET_META } from '../../../index'

const { formatDate } = date

// Declared as const up here as this doesn't need to be reactive and we don't want it re-instantiating
// for each component created using BaseMixin
const DummyUser = {
  ...new UserDto(),
  permissionQuery: new PermissionService().configure(new UserDto())
}

@Component({
  // @ts-ignore
  async preFetch ({ store, currentRoute }) {
    if (process.env.SERVER) {
      if (currentRoute?.meta?.metaInstructions?.module) {
        const docId = currentRoute.params.id
        const storeService = new StoreService()
        storeService.configure(store)

        const configService = new ConfigService()
        configService.configure(requireConfigFile())

        const axiosService = new AxiosService(storeService, configService, new VueI18n())

        await axiosService.axios.post('auth/meta-info', {
          id: docId,
          module: toCamelCase(currentRoute.meta.metaInstructions.module),
          moduleUrl: currentRoute.path
        }).then((r: { data: MetaState }) => {
          store.dispatch(SET_META, r.data)
        })
      }
    }
  },
  meta () {
    return {
      title: this.$store.getters.getMeta.title,
      titleTemplate: (title: string) => `${title} | Booksprout`,
      meta: {
        description: {
          name: 'description',
          content: this.$store.getters.getMeta.description
        },
        keywords: {
          name: 'keywords',
          content: this.$store.getters.getMeta.keywords
        },
        ogTitle: {
          name: 'og:title',
          content: this.$store.getters.getMeta.ogTitle
        },
        ogImage: {
          name: 'og:image',
          content: this.$store.getters.getMeta.ogImage
        },
        ogImageWidth: {
          name: 'og:image:width',
          content: '1200'
        },
        ogImageHeight: {
          name: 'og:image:height',
          content: '630'
        },
        ogUrl: {
          name: 'og:url',
          content: this.$store.getters.getMeta.ogUrl
        }
      },
      link: {
        canonical: {
          rel: 'canonical',
          href: this.$store.getters.getMeta.canonicalUrl
        }
      }
    }
  }
})
export class CommonBaseMixin extends Vue {
  @inject('LinkService')
  linkService!: LinkService

  isAuthenticated: boolean = false

  @Getter('authenticatedUser') private _authenticatedUser!: AuthenticatedUser
  @Getter('mimicUser') private _mimicUser!: AuthenticatedUser

  @Watch('$q.screen.width')
  onScreenWidthChange (to: number, from: number) {
    if (from === 0 && to > 0 && this.renderNotCalled === true) {
      this.renderNotCalled = false
      this.onClientRendered()
    }
  }

  get isMobileApp () {
    // @ts-ignore
    return !!this.$q.capacitor
  }

  renderNotCalled = true

  MODULE_CONSTANTS = bsConstants
  AMAZON = bsConstants.ARCS.REVIEWS.FLAGS.AMAZON
  GOOGLE = bsConstants.ARCS.REVIEWS.FLAGS.GOOGLE
  GOOD_READS = bsConstants.ARCS.REVIEWS.FLAGS.GOOD_READS
  BARNES = bsConstants.ARCS.REVIEWS.FLAGS.BARNES
  BOOK_BUB = bsConstants.ARCS.REVIEWS.FLAGS.BOOK_BUB
  KOBO = bsConstants.ARCS.REVIEWS.FLAGS.KOBO
  ITUNES = bsConstants.ARCS.REVIEWS.FLAGS.ITUNES
  SMASH_WORDS = bsConstants.ARCS.REVIEWS.FLAGS.SMASH_WORDS
  AUDIBLE_US = bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_US
  AUDIBLE_UK = bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_UK
  AUDIO_AUTHORS_DIRECT = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_AUTHORS_DIRECT
  AUDIO_KOBO = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_KOBO
  AUDIO_BOOK_BUB = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_BOOK_BUB
  AUDIO_GOOGLE = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_GOOGLE
  AUDIO_BARNES = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_BARNES
  AUDIO_ITUNES = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_ITUNES
  AUDIO_GOOD_READS = bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_GOODREADS

  DEV_MODE = ['dev', 'testing', 'beta'].includes(process.env.BUILD_MODE || 'production')

  /**
   * Try and return the logged in user but if not, return a blank user. This means we don't need
   * lots of checks in the templates to see if the user is undefined.
   */
  get authenticatedUser (): AuthenticatedUser {
    return this._authenticatedUser
      ? this._authenticatedUser
      : DummyUser
  }

  /**
   * Try and return the mimic user but if not, return a blank user. This means we don't need
   * lots of checks in the templates to see if the user is undefined.
   */
  get mimicUser (): AuthenticatedUser {
    return this._mimicUser
      ? this._mimicUser
      : DummyUser
  }

  get isMimic () {
    return validInt(this.mimicUser.id)
  }

  get mimicOrAuthenticatedUser (): AuthenticatedUser {
    return this.isMimic ? this.mimicUser : this.authenticatedUser
  }

  get isReviewerApp () {
    return IsReviewerApp()
  }

  navigateToOtherApp (url: string) {
    const apps = ['admin', 'publisher', 'reviewer']

    /**
     * Helper function to determine which site we need to redirect to.
     * For local dev, we need to work out the localhost domain
     * For live, it doesn't matter - just forward to the url (redirect)
     * @param goTo
     */
    const navigateToCorrectApp = (goTo: string) => {
      const urlParts = document.location.href.split('/')
      const currentApp = urlParts[3]
      const goToApp = goTo.split('/')[0]
      let remainingGoToUrl = goTo.substring(goTo.indexOf('/'))
      if (goToApp === remainingGoToUrl) {
        remainingGoToUrl = ''
      }
      const homeUrl = goToApp ? (this.MODULE_CONSTANTS.APP.HOME_URLS as HasIndex)[goToApp.toUpperCase()] : ''

      let isNavigatingToOtherApp = !url.startsWith(currentApp)
      // Root domain with no /publisher or /reviewer on it
      // work out if we're on the same domain or not.
      if (!isNavigatingToOtherApp && !currentApp) {
        isNavigatingToOtherApp = document.location.href + url !== this.getAppHost(goTo)
      }

      if (isNavigatingToOtherApp && !this.isMobileApp) {
        document.location.href = this.getAppHost(goToApp, false) + (remainingGoToUrl || homeUrl) + (this.$route.query.forward ? '?forward=' + this.$route.query.forward : '')
      } else {
        // When using VueRouter, path will be taken into account already so remove the publicPath part and push
        let pushTo = goTo.substring(goTo.indexOf('/') + 1)
        apps.forEach(e => pushTo = pushTo.replace(e, ''))
        // Always make sure we're directing to the root of the app before pushing
        void this.$router.push(validStr(pushTo) ? (pushTo.startsWith('/') ? pushTo : '/' + pushTo) : homeUrl).catch(() => {
          // do nothing, routeGuard redirected user
          // fixes "Uncaught (in promise) undefined" error
        })
      }
    }

    navigateToCorrectApp(url)
  }

  getAppHost (appName: string = 'publisher', trailingSlash = true): string {
    const testingMode = [process.env.ENVIRONMENT, process.env.BUILD_MODE].includes('testing') ? 'testing' : void 0
    const dev = {
      reviewer: 'https://dev.booksprout.co/reviewer',
      admin: 'https://dev.booksprout.co/admin',
      publisher: 'https://dev.booksprout.co/publisher',
      website: 'dev.booksprout.co'
    }

    const hosts: HasIndex = {
      development: dev,
      dev,
      staging: {
        reviewer: 'https://staging.booksprout.co/reviewer',
        admin: 'https://staging.booksprout.co/admin',
        publisher: 'https://staging.booksprout.co/publisher',
        website: 'staging.booksprout.co'
      },
      testing: {
        reviewer: 'https://testing.booksprout.co/reviewer',
        admin: 'https://testing.booksprout.co/admin',
        publisher: 'https://testing.booksprout.co/publisher',
        website: 'testing.booksprout.co'
      },
      beta: {
        reviewer: 'https://beta.booksprout.co/reviewer',
        admin: 'https://beta.booksprout.co/admin',
        publisher: 'https://beta.booksprout.co/publisher',
        website: 'https://beta.booksprout.co'
      },
      sandbox: {
        reviewer: 'https://sandbox.booksprout.co/reviewer',
        admin: 'https://sandbox.booksprout.co/admin',
        publisher: 'https://sandbox.booksprout.co/publisher',
        website: 'https://sandbox.booksprout.co'
      },
      master: {
        reviewer: 'https://booksprout.co/reviewer',
        admin: 'https://booksprout.co/admin',
        publisher: 'https://booksprout.co/publisher',
        website: 'https://booksprout.co'
      }
    }

    const mode = testingMode || process.env.BUILD_MODE || process.env.NODE_ENV || 'master'
    return hosts[mode][appName] + (trailingSlash ? '/' : '')
  }

  linkToOtherApp (name: string, url: string): string {
    if (name.startsWith('/')) {
      name = name.replace('/', '')
    }

    if (url.startsWith('/')) {
      url = url.replace('/', '')
    }

    const host = this.getAppHost(name)
    return host + (url || this.linkService.homeUrl)
  }

  showMessage (message: string | TranslateResult, ...optionalParams: any[]): void {
    this.$q.dialog({
      component: this.$bs.dialogComponent,
      parent: this,
      heading: this.$tc('system.actions.messageDialogHeading'),
      message: message.toString().concat(optionalParams.join(' '))
    })
  }

  showError (message: string | TranslateResult, ...optionalParams: any[]) {
    // we are showing login dialog now, so don't show error
    if (message === 'Unauthorized') {
      return
    }

    if (this.$store.getters.getLastError !== message) {
      this.$store.dispatch(SET_LAST_ERROR, message)
      this.$q.dialog({
        component: this.$bs.dialogComponent,
        parent: this,
        error: true,
        heading: this.$tc('system.actions.errorDialogHeading'),
        message: message?.toString().concat(optionalParams.join(' '))
      })

      setTimeout(() => {
        this.$store.dispatch(SET_LAST_ERROR, '')
      }, 2000)
    }
  }

  ellipsis (text: string, length: number, ellipsisStr: string = '...', cb: any = void 0) {
    return ellipsis(text, length, ellipsisStr, cb)
  }

  formatDate (timestamp: number | Date, format: string = DATE_FORMAT): string {
    if (!timestamp) {
      return this.$tc('system.labels.unknownDate')
    }

    // If we've used Date.now() we'll get the time including milliseconds.
    // remove them before formatting the date
    if (typeof timestamp === 'number' && (validInt(timestamp) && timestamp.toString().length === 10)) {
      timestamp = timestamp * 1000
    }

    return formatDate(new Date(timestamp).toUTCString(), format)
  }

  toClipboard (text: any) {
    copyToClipboard(text).then(() => {
      this.showMessage(this.$t('system.labels.copiedToClipboard'))
    }).catch(() => {
      this.showError(this.$t('system.labels.couldNotCopyToClipboard'))
    })
  }

  hasTieredPermission (needsRole: string): boolean {
    return !!this.mimicOrAuthenticatedUser.permissionQuery.hasTieredPermission(needsRole)
  }

  validEmail (email: string): boolean {
    return emailValidator.validate(email)
  }

  onClientRendered () {
    // Nothing here. Can be overridden in base classes to do stuff once when SSR has rendered the page
  }

  getPenNameName (book: BookDto, field: string) {
    const penName = book.authorPenName ? (book.authorPenName as HasIndex)[field] : book.penName
    return this.ellipsis(penName, 50)
  }

  getDisplayName (name: string | undefined): string {
    if (name && name.trim()) {
      return name
    }

    return this.$tc('system.labels.anonymous')
  }

  selectInput (e: any) {
    e.target.focus()
    e.target.select()
  }

  applyUrlFilterParam (assignToVar: HasIndex, propName: string, value: any, type: 'string' | 'intArray' | 'array' | 'int' = 'string') {
    switch (type) {
      case 'string':
        if (validStr(value)) {
          assignToVar[propName] = value.toString()
        }
        break
      case 'intArray':
        if (validStr(value) && value !== 'undefined') {
          assignToVar[propName] = value.split(',').map((m: string) => parseInt(m.trim()))
        }
        break
      case 'array':
        if (validStr(value) && value !== 'undefined') {
          assignToVar[propName] = value.split(',').map((m: string) => m.trim())
        }
        break
      case 'int':
        if (validInt(value)) {
          assignToVar[propName] = parseInt(value)
        }
    }
  }

  setUrlFilterParams (data: any) {
    updateUrlHash(data, void 0, this.isMobileApp ? '?' : void 0)
  }

  minusSubDiscount (amount: number, type: string = 'month') {
    return this.minusDiscount(
      amount,
      type === 'month'
        ? this.MODULE_CONSTANTS.SUBSCRIPTIONS.DISCOUNTS.MONTH
        : this.MODULE_CONSTANTS.SUBSCRIPTIONS.DISCOUNTS.YEAR,
      this.MODULE_CONSTANTS.SUBSCRIPTIONS.DISCOUNTS.END_DATE
    )
  }

  minusSubDiscountNoDate (amount: number, type: string = 'month') {
    return this.minusDiscountNoDate(
      amount,
      type === 'month'
        ? this.MODULE_CONSTANTS.SUBSCRIPTIONS.DISCOUNTS.MONTH
        : this.MODULE_CONSTANTS.SUBSCRIPTIONS.DISCOUNTS.YEAR
    )
  }

  minusDiscount (amount: number, pcDiscount: number, untilDate?: Date) {
    if (untilDate && newDate() <= untilDate) {
      return this.minusDiscountNoDate(amount, pcDiscount)
    }

    return amount
  }

  minusDiscountNoDate (amount: number, pcDiscount: number) {
    return roundWithPrecision(amount * ((100 - pcDiscount) / 100), 2)
  }

  setLocalStorage (key: string, value: number | boolean | string): void {
    LocalStorage.set(key, value)
  }

  getLocalStorage (key: string): number | boolean | string | null {
    return LocalStorage.getItem<number | boolean | string>(key)
  }

  userFriendlySiteDescription (description: string): string {
    if (description.endsWith('(Audio)')) {
      return description.replace('(Audio)', '').trim()
    }

    return description
  }

  setPageMeta (module: string, title: string | undefined = void 0) {
    // use $t() method, not $tc as i18n will treat "| Booksprout" as plural translation
    void this.$store.dispatch(SET_META, {
      title: this.$t(`meta.module.${module}.title`, [title]),
      // author app is under login, seo doesn't matter
      // hence why 'reviewer' path is hardcoded
      canonicalUrl: locationOrigin() + '/reviewer' + this.$router.currentRoute.path // don't use fullPath
    })
  }

  mounted () {
    this.isAuthenticated = this.$store.getters.isAuthenticated
  }
}
